:root {
  --color_highlight: #CCF3DF;
  --color_brand-dark: #1a91da;
  --color_brand-light: #1a91da;
  --color_brand-light-opaque: rgb(54, 145, 233, .125);
  --color_brand: #10a1f2;

  --color_white: #fff;
  --color_black: #2d3436;
  --color_black-opaque: rgba(0, 0, 0, .8);
  --color_black-opaquer: rgba(0, 0, 0, .65);
  --color_black-opaquest: rgba(0, 0, 0, .3);
  --color_gold: #ffd700;
  --color_red: #de2960;
  --color_red-dark: #c72c5b;

  --radius-small: 8px;
  --radius-circle: 9999px;

  --fs_xs: 0.8571428571rem;
  --fs_s: 0.9285714286rem;
  --fs_n: 1rem;
  --fs_m: 1.0714285714rem;
  --fs_l: 1.1428571429rem;
  --fs_xl: 1.3571428571rem;
  --fs_xxl: 1.7142857143rem;

  --font_family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, Ubuntu, "Helvetica Neue", sans-serif;

  /* Default light theme */
  --solid_color_primary: #fff;
  --solid_color_primary-opaque:rgba(255, 255, 255,.6);
  --solid_color_secondary: #e2e8ec;
  --solid_color_secondary-dark: #d9e0e5;
  --solid_color_tertiary: #f5f5f7;
  --solid_color_block: #f5f8fa;
  --solid_color_highlight: rgba(224, 234, 66, .125);

  --text_color_primary: #2d3436;
  --text_color_secondary: #4b4f55;
  --text_color_tertiary: #777;

  --border_color_secondary: #ececed;

  /* Navigation bar. Only themes. Non-editable */
  --navigation_background: var(--color_brand);
  --navigation_blend: var(--color_brand-dark);
  --navigation_primary: var(--color_white);
  --navigation_brand: var(--color_white);
  --navigation_search: var(--color_brand-light);
}

::selection {
  background: var(--color_highlight);
}

::-moz-selection {
  background: var(--color_highlight);
}

:root[no-circle] {
  --radius-circle: 0 !important;
}

:root[no-radius] {
  --radius-small: 0 !important;
}

:root[theme='muted'] {
  --solid_color_primary: #222 !important;
  --solid_color_primary-opaque:rgba(34, 34, 34, .6) !important;
  --solid_color_secondary: #4f5050 !important;
  --solid_color_secondary-dark: #424343 !important;
  --solid_color_tertiary: #333 !important;
  --solid_color_block: #2d2d2d !important;

  --text_color_primary: #fff !important;
  --text_color_secondary: #999 !important;
  --text_color_tertiary: #656565 !important;

  --border_color_secondary: #424141 !important;

  /* Navigation bar. Only themes. Non-editable */
  --navigation_background: #000 !important;
  --navigation_blend: var(--text_color_secondary) !important;
  --navigation_primary: var(--text_color_primary) !important;
  --navigation_brand: var(--color_brand) !important;
  --navigation_search: var(--solid_color_tertiary) !important;
}

:root[theme='black'] {
  --solid_color_primary: #13171b !important;
  --solid_color_primary-opaque:rgba(19, 23, 27, .6) !important;
  --solid_color_secondary: #343434 !important;
  --solid_color_secondary-dark: #282828 !important;
  --solid_color_tertiary: #000 !important;
  --solid_color_block: #202327 !important;

  --text_color_primary: #cccbcb !important;
  --text_color_secondary: #888 !important;
  --text_color_tertiary: #656565 !important;

  --border_color_secondary: #343434 !important;

  /* Navigation bar. Only themes. Non-editable */
  --navigation_background: #000 !important;
  --navigation_blend: var(--text_color_secondary) !important;
  --navigation_primary: var(--text_color_primary) !important;
  --navigation_brand: var(--color_brand) !important;
  --navigation_search: var(--solid_color_block) !important;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--solid_color_tertiary);
}

body {
  overflow-y: scroll;
  overscroll-behavior-y: none;
}

code,
pre {
  font-family: monospace !important;
}

.overflowYScroll {
  overflow: hidden;
  overflow-y: scroll;
}

.statusContent,
.statusContent * {
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--fs_m);
  line-height: 1.3125;
  overflow-wrap: break-word;
  color: var(--text_color_primary);
  font-family: var(--font_family);
}

.statusContent p {
  min-height: 18px;
}

.statusContent p:not(:last-of-type) {
  margin-bottom: 18px;
}

.statusContent em {
  font-style: italic;
}

.statusContent strong {
  font-weight: 700;
}

.statusContent strike,
.statusContent del {
  text-decoration: line-through;
}

.statusContent h1 {
  font-size: var(--fs_xl);
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.statusContent h1 * {
  font-size: var(--fs_xl) !important;
}

.statusContent ul,
.statusContent ol {
  padding-left: 40px;
  margin: 0.5rem 0;
}

.statusContent ul {
  /* list-style-type: disc; */
}

.statusContent ol {
  /* list-style-type: disc; */
}

.statusContent code {
  background-color: var(--border_color_secondary);
  color: var(--text_color_secondary) !important;
  font-size: var(--fs_n) !important;
  padding: 0.25em 0.5em;
}

.dangerousContent,
.dangerousContent * {
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--fs_n);
  line-height: 1.3125;
  overflow-wrap: break-word;
  color: var(--text_color_primary);
  font-family: var(--font_family);
}

.statusContent a,
.dangerousContent a,
.statusContent a *,
.dangerousContent a * {
  color: var(--color_brand);
  text-decoration: none;
}

.statusContent a:hover,
.dangerousContent a:hover {
  text-decoration: underline;
}

.statusCardVideo iframe {
  height: 100% !important;
  width: 100% !important;
}

.default {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
  border: 0 solid var(--color_black);
  z-index: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.text {
  display: inline;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: var(--font_family);
}

.font {
  font-family: var(--font_family);
}

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.overflowWrapBreakWord {
  overflow-wrap: break-word;
}

.inherit {
  color: inherit;
  white-space: inherit;
}

.inheritFill { fill: inherit; }

.flexNormal {
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
}

.flex1 { flex: 1; }
.flexGrow1 { flex-grow: 1; }
.flexShrink1 { flex-shrink: 1; }

.flexRow { flex-direction: row; }
.flexWrap { flex-wrap: wrap; }
.flexColumnReverse { flex-direction: column-reverse; }

.alignItemsEnd { align-items: flex-end; }
.alignItemsStart { align-items: flex-start; }
.alignItemsCenter { align-items: center; }

.justifyContentSpaceBetween { justify-content: space-between; }
.justifyContentSpaceAround { justify-content: space-around; }
.justifyContentCenter { justify-content: center; }
.justifyContentEnd { justify-content: flex-end; }

.overflowHidden {
  overflow-x: hidden;
  overflow-y: hidden;
}

.overflowYHidden { overflow-y: hidden; }
.overflowXScroll { overflow-x: scroll; }

.textOverflowEllipsis {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whiteSpaceNoWrap { white-space: nowrap; }

.outlineNone { outline: none; }
.outlineOnFocus:focus {
  box-shadow: 0 0 0 5px rgba(21, 156, 228, 0.4);
} 

.resizeNone { resize: none; }

.circle { border-radius: var(--radius-circle); }
.radiusSmall { border-radius: var(--radius-small); }
.topLeftRadiusSmall { border-top-left-radius: var(--radius-small); }
.topRightRadiusSmall { border-top-right-radius: var(--radius-small); }
.bottomRightRadiusSmall { border-bottom-right-radius: var(--radius-small); }
.bottomLeftRadiusSmall { border-bottom-left-radius: var(--radius-small); }

.borderColorWhite { border-color: var(--color_white); }
.borderColorPrimary { border-color: var(--solid_color_primary); }
.borderColorSecondary { border-color: var(--border_color_secondary); }
.borderColorBrand { border-color: var(--color_brand); }
.borderColorTransparent { border-color: transparent; }

.borderRight1PX { border-right-width: 1px; }
.borderBottom1PX { border-bottom-width: 1px; }
.borderBottom6PX { border-bottom-width: 6px; }
.borderLeft1PX { border-left-width: 1px; }
.borderTop1PX { border-top-width: 1px; }
.borderTop2PX { border-top-width: 2px; }

.border1PX { border-width: 1px; }
.border2PX { border-width: 2px; }
.border6PX { border-width: 6px; }

.borderBottom2PX { border-bottom-width: 2px; }

.borderDashed { border-style: dashed; }

.displayNone { display: none; }
.displayBlock { display: block; }
.displayInline { display: inline; }
.displayFlex { display: flex !important; }

.cursorText { cursor: text; }
.cursorPointer { cursor: pointer; }
.cursorNotAllowed { cursor: not-allowed; }

.backgroundCandy {
  background-image: linear-gradient(-45deg, 
    rgba(255, 255, 255, 0.15) 10%, 
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.15) 20%,
    rgba(255, 255, 255, 0.15) 30%,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.15) 40%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0.15) 60%,
    rgba(255, 255, 255, 0.15) 70%,
    rgba(255, 255, 255, 0) 70%,
    rgba(255, 255, 255, 0) 80%,
    rgba(255, 255, 255, 0.15) 80%,
    rgba(255, 255, 255, 0.15) 90%,
    rgba(255, 255, 255, 0) 90%,
    rgba(255, 255, 255, 0)
  );
}

.bgTransparent { background-color: transparent; }

.bgLoading { background-color: #ccc; }

.bgSubtle { background-color: var(--solid_color_block); }
.bgSubtle_onHover:hover { background-color: var(--solid_color_block); }

.bgSecondary { background-color: var(--solid_color_secondary); }
.bgSecondaryDark_onHover:hover { background-color: var(--solid_color_secondary-dark); }

.bgTertiary { background-color: var(--solid_color_tertiary); }

.bgPrimary { background-color: var(--solid_color_primary); }
.bgPrimaryOpaque { background-color: var(--solid_color_primary-opaque) }

.bgWhite { background-color: var(--color_white); }

.bgBlack { background-color: var(--color_black); }
.bgBlackOpaque { background-color: var(--color_black-opaquer); }
.bgBlackOpaque_onHover:hover { background-color: var(--color_black-opaque); }
.bgBlackOpaquest_onHover:hover { background-color: var(--color_black-opaquest); }

.bgBrand { background-color: var(--color_brand); }
.bgBrand_onHover:hover { background-color: var(--color_brand); }

.bgBrandLight { background-color: var(--color_brand-light); }
.bgBrandLightOpaque { background-color: var(--color_brand-light-opaque); }
.bgBrandLightOpaque_onHover:hover { background-color: var(--color_brand-light-opaque); }

.bgBrandDark { background-color: var(--color_brand-dark); }
.bgBrandDark_onHover:hover { background-color: var(--color_brand-dark); }

.bgRed { background-color: red; }
.bgDanger { background-color: var(--color_red); }
.bgDangerDark_onHover:hover { background-color: var(--color_red-dark); }

.bgPro { background-color: var(--color_gold); }
.bgDonor { background-color: #4DA6FF; }
.bgInvestor { background-color: #6DD900; }

/*  */

.colorPrimary { color: var(--text_color_primary); }

.colorWhite { color: var(--color_white); }
.colorWhite_onHover:hover { color: var(--color_white); }

.colorTertiary { color: var(--text_color_tertiary); }
.colorSecondary { color: var(--text_color_secondary); }

.colorBrand { color: var(--color_brand); }
.colorGabPro { color: var(--color_gold); }

.colorBGPrimary { color: var(--solid_color_primary); }

/*  */

.fillPrimary { fill: var(--text_color_primary); }
.fillSecondary { fill: var(--text_color_secondary); }

.fillWhite { fill: var(--color_white); }
.fillWhite_onHover:hover { fill: var(--color_white); }

.fillBrand { fill: var(--color_brand); }
.fillBrandDark { fill: var(--color_brand-dark); }
.fillBrand_onHover:hover { fill: var(--color_brand);}

.fillColorGabPro { fill: var(--color_gold); }

/*  */

.topNeg50PX { top: -50px; }
.top0 { top: 0; }
.top120PX { top: 120px; }
.top60PC { top: 60%; }
.top50PC { top: 50%; }

.bottom0 { bottom: 0; }
.bottom55PX { bottom: 55px; }
.bottomAuto { bottom: auto; }

.left0 { left: 0px; }
.left50PC { left: 50%; }

.right0 { right: 0px; }
.rightAuto { right: auto; }

/*  */

.lineHeight125 { line-height: 1.25em; }
.lineHeight15 { line-height: 1.5em; }
.lineHeight2 { line-height: 2em; }

/*  */

.posSticky { position: sticky; }
.posFixed { position: fixed; }
.posAbs { position: absolute; }

.noSelect { user-select: none; }

.resizeVertical { resize: vertical; }

.heightMax100VH { max-height: 100vh; }
.heightMax100PC { max-height: 100%; }
.heightMax80VH { max-height: 80vh; }
.heightMax200PX { max-height: 200px; }
.heightMax56PX { max-height: 56px; }
.heightCalc53PX { height: calc(100vh - 53px); }
.heightCalc80VH106PX { height: calc(80vh - 106px); }

.heightMin100VH { min-height: 100vh; }
.heightMin50VH { min-height: 50vh; }
.heightMin98PX { min-height: 98px; }
.heightMin80PX { min-height: 80px; }
.heightMin58PX { min-height: 58px; }
.heightMin53PX { min-height: 53px; }
.heightMin50PX { min-height: 50px; }
.heightMin28PX { min-height: 28px; }

.height100PC { height: 100%; }
.height350PX { height: 350px; }
.height260PX { height: 260px; }
.height220PX { height: 220px; }
.height215PX { height: 215px; }
.height200PX { height: 200px; }
.height158PX { height: 158px; }
.height122PX { height: 122px; }
.height98PX { height: 98px; }
.height72PX { height: 72px; }
.height60PX { height: 60px; }
.height53PX { height: 53px; }
.height40PX { height: 40px; }
.height24PX { height: 24px; }
.height22PX { height: 22px; }
.height20PX { height: 20px; }
.height14PX { height: 14px; }
.height10PX { height: 10px; }
.height4PX { height: 4px; }
.height1PX { height: 1px; }
.heightAuto { height: auto; }

.maxWidth100PC { max-width: 100%; }
.maxWidth80PC { max-width: 80%; }
.maxWidth640PX { max-width: 640px; }
.maxWidth180PX { max-width: 180px; }
.maxWidth100PC86PX { max-width: calc(100% - 86px); }
.maxWidth100PC42PX { max-width: calc(100% - 42px); }

.minWidth330PX { min-width: 330px; }
.minWidth20PX { min-width: 20px; }
.minWidth14PX { min-width: 14px; }

.width100PC { width: 100%; }
.width50PC { width: 50%; }
.width25PC { width: 25%; }
.width1255PX { width: 1255px; }
.width1015PX { width: 1015px; }
.width645PX { width: 645px; }
.width340PX { width: 340px; }
.width330PX { width: 330px; }
.width250PX { width: 240px; }
.width240PX { width: 240px; }
.width115PX { width: 115px; }
.width84PX { width: 84px; }
.width76PX { width: 76px; }
.width72PX { width: 72px; }
.width60PX { width: 60px; }
.width50PX { width: 50px; }
.width20PX { width: 20px; }
.width14PX { width: 14px; }
.width10PX { width: 10px; }
.width4PX { width: 4px; }
.width1PX { width: 1px; }
.widthAuto { width: auto; }

@media (min-width: 1480px) {
  .width1015PX {
    width: 1080px;
  }

  .width645PX {
    width: 700px;
  }

  .width340PX {
    width: 350px;
  }

  .width240PX {
    width: 250px;
  }
}

@media (min-width: 1160px) and (max-width: 1280px) {
  .width1015PX {
    width: 910px;
  }

  .width645PX {
    width: 580px;
  }

  .width340PX {
    width: 300px;
  }

  .width240PX {
    width: 230px;
  }

  .width1255PX {
    width: 1140px;
  }
}

@media (min-width: 1080px) and (max-width: 1160px) {
  .width1015PX {
    width: 850px;
  }

  .width645PX {
    width: 525px;
  }

  .width340PX {
    width: 300px;
  }

  .width240PX {
    width: 210px;
  }

  .width1255PX {
    width: 1060px;
  }
}

@media (min-width: 992px) and (max-width: 1080px) {
  .width1015PX {
    width: 780px;
  }

  .width645PX {
    width: 470px;
  }

  .width340PX {
    width: 285px;
  }

  .width240PX {
    width: 170px;
  }

  .width1255PX {
    width: 950px;
  }
}

@media (min-width: 0px) and (max-width: 992px) {
  .width1015PX {
    max-width: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .width645PX {
    max-width: 600px;
    width: 100%;
  }

  .width340PX {
    width: 0px;
  }

  .width240PX {
    width: 0px;
  }

  .width1255PX {
    width: 100%;
  }

  :global(.emoji-mart) {
    border: 0 !important;
    border-radius: 0 !important;
    width: 100% !important;
    min-width: 100% !important;
  }

  .modal {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .modal > div {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .modal:after {
    content: '';
    display: block;
    position: absolute;
    width: 70px;
    height: 6px;
    background-color: var(--solid_color_primary);
    border-radius: var(--radius-circle);
    left: calc(100% / 2 - 35px);
    top: -20px;
  }
}

.textAlignLeft { text-align: left; }
.textAlignCenter { text-align: center; }

.fs24PX { font-size: var(--fs_xxl); }
.fs19PX { font-size: var(--fs_xl); }
.fs16PX { font-size: var(--fs_l); }
.fs15PX { font-size: var(--fs_m); }
.fs14PX { font-size: var(--fs_n); }
.fs13PX { font-size: var(--fs_s); }
.fs12PX { font-size: var(--fs_xs); }
.fs0 { font-size: 0; }

.fontWeightNormal { font-weight: 400; }
.fontWeightMedium { font-weight: 500; }
.fontWeightBold { font-weight: 600; }
.fontWeightExtraBold { font-weight: 800; }

.noUnderline { text-decoration: none; }
.underline { text-decoration: underline; }
.underline_onHover:hover { text-decoration: underline; }

.objectFitCover { object-fit: cover; }
.objectFitContain { object-fit: contain; }

.textShadow {
  text-shadow: 0 0 5px var(--color_black);
}

.z1 { z-index: 1; }
.z2 { z-index: 2; }
.z3 { z-index: 3; }
.z4 { z-index: 4; }
.z5 { z-index: 5; }

.my10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m1PX { margin: 1px; }

.mr15 { margin-right: 15px; }
.mr10 { margin-right: 10px; }
.mr5 { margin-right: 5px; }
.mr2 { margin-right: 2px; }
.mrAuto { margin-right: auto; }

.ml15 { margin-left: 15px; }
.ml10 { margin-left: 10px; }
.ml5 { margin-left: 5px; }
.mlAuto { margin-left: auto; }
.mlNeg5PX { margin-left: -5px; }

.mb15 { margin-bottom: 15px; }
.mb10 { margin-bottom: 10px; }
.mb5 { margin-bottom: 5px; }
.mbNeg5PX { margin-bottom: -5px; }

.mt15 { margin-top: 15px; }
.mt10 { margin-top: 10px; }
.mt5 { margin-top: 5px; }
.mt2 { margin-top: 2px; }
.mtAuto { margin-top: auto; }
.mtNeg26PX { margin-top: -26px; }
.mtNeg32PX { margin-top: -32px; }
.mtNeg50PX { margin-top: -50px; }
.mtNeg75PX { margin-top: -75px; }

.pt5625PC { padding-top: 56.25%; }
.pt25PC { padding-top: 25%; }
.pt53PX { padding-top: 53px; }
.pt15 { padding-top: 15px; }
.pt10 { padding-top: 10px; }
.pt5 { padding-top: 5px; }
.pt2 { padding-top: 2px; }

.pb15 { padding-bottom: 15px; }
.pb10 { padding-bottom: 10px; }
.pb5 { padding-bottom: 5px; }
.pb3 { padding-bottom: 3px; }

.pl35 { padding-left: 35px; }
.pl25 { padding-left: 25px; }
.pl20 { padding-left: 20px; }
.pl15 { padding-left: 15px; }
.pl10 { padding-left: 10px; }
.pl5 { padding-left: 5px; }
.pl0 { padding-left: 0; }

.pr15 { padding-right: 15px; }
.pr10 { padding-right: 10px; }
.pr5 { padding-right: 5px; }
.pr0 { padding-right: 0; }

.px15 {
  padding-left: 15px;
  padding-right: 15px;
}

.py5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.py10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.px2 {
  padding-left: 2px;
  padding-right: 2px;
}

.px5 {
  padding-left: 5px;
  padding-right: 5px;
}

.px10 {
  padding-left: 10px;
  padding-right: 10px;
}

.opacity0 { opacity: 0; }
.opacity05 { opacity: 0.5; }
.opacity1 { opacity: 1; }

.boxShadow1 { box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .25); }
.boxShadowPopover { box-shadow: 0 0 15px -5px rgba(0,0,0,0.15); }
.boxShadowBlock { box-shadow: 0 1px 2px rgba(0,0,0,0.2); }
.boxShadowDot { box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 6px #000; }

.boxShadowNone .boxShadowBlock {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.boxShadowAvatarPro {
  box-shadow: 0 0 0 2px var(--color_gold);
}

.boxShadowProfileAvatar {
  box-shadow: 0 0 0 6px var(--solid_color_primary);
}

.listStyleNone {
  list-style: none;
}

.videoEase {
  transition: left 0.25s linear, width 0.25s linear;
}

.videoPlayerControlsBackground {
  background: linear-gradient(0deg, rgba(0,0,0,.85), rgba(0,0,0,.45) 60%, transparent);
}

.videoPlayerSeek:before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 4px;
  top: 10px;
  width: 100%;
  height: 40px;
}

.videoPlayerVolume {
  width: 24px;
  left: 68px;
  bottom: 60px;
}

.select {
  height: 42px;
  line-height: 42px;
  font-size: var(--fs_xl);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

:global(.emojione) {
  margin: -3px 0 0;
  height: 20px;
  width: 20px;
}

:global(.emoji-mart-emoji span) {
  background-repeat: no-repeat;
  background-position: center;
}

:global(.invisible) {
  font-size: 0px;
  display: inline;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: var(--font_family);
}

:global(.ellipsis) {
  display: inline;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: var(--font_family);
  color: inherit;
  user-select: none;
}

:global(.ellipsis):after {
  display: inline;
  content: '…';
  position: relative;
}

/* .videoPlayerVolume:before {
  content: '';
  display: block;
  position: absolute;
} */

.visiblyHidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  composes: posAbs;
}

.visibilityHidden {
  visibility: hidden;
}

.highlightedComment {
  background-color: var(--solid_color_highlight);
}

.searchInput::placeholder {
  color: rgba(255,255,255,0.65);
  opacity: 1;
}

.searchInput:-ms-input-placeholder {
  color: rgba(255,255,255,0.65);
}

.searchInput::-ms-input-placeholder {
  color: rgba(255,255,255,0.65);
}

.noScrollbar::-webkit-scrollbar {
  display: none;
}

.noScrollbar {
  -ms-overflow-style: none;
}

.saveAreaInsetPB {
  padding-bottom: env(safe-area-inset-bottom, 0);
}

.saveAreaInsetPT {
  padding-top: env(safe-area-inset-top, 0);
}

.saveAreaInsetPL {
  padding-left: env(safe-area-inset-left, 0);
}

.saveAreaInsetPR {
  padding-right: env(safe-area-inset-right, 0);
}

.saveAreaInsetMR {
  margin-right: env(safe-area-inset-right, 0);
}

.saveAreaInsetMB {
  margin-bottom: env(safe-area-inset-bottom, 0);
}

.bgNavigation {
  background-color: var(--navigation_background);
}

.bgNavigationBlend {
  background-color: var(--navigation_blend);
}

.fillNavigation {
  fill: var(--navigation_primary);
}

.fillNavigationBlend {
  fill: var(--navigation_blend);
}

.colorNavigation {
  color: var(--navigation_primary);
}

.fillNavigationBrand {
  fill: var(--navigation_brand);
}

.searchNavigation {
  background-color: var(--navigation_search);
}

.gabsocial {
  composes: saveAreaInsetPB saveAreaInsetPT saveAreaInsetPL saveAreaInsetPR;
}

/**
 * Rich Text Editor
 */
:global(.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root) {
  display: none;
}

/* :global(.public-DraftEditorPlaceholder-inner) {
  font-weight: 400;
  font-size: var(--fs_l);
} */

.statusContent blockquote,
:global(.RichEditor-blockquote) {
  border-left: 5px solid var(--border_color_secondary);
  color: var(--text_color_secondary);
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.statusContent pre,
:global(.public-DraftStyleDefault-pre) {
  background-color: rgba(0,0,0,.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: var(--fs_l);
  padding: 10px 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/*  */

:global(.emoji-mart-search input) {
  border-radius: var(--radius-circle) !important;
}

/*  */

:global(.react-datepicker) {
  border: 0 !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
}

:global(.react-datepicker__header) {
  background-color: transparent !important;
  border-color: var(--border_color_secondary) !important;
}

:global(.react-datepicker__month-container) {
  float: none !important;
  width: calc(100% - 103px) !important;
  border-color: var(--border_color_secondary) !important;
}

:global(.react-datepicker__day) {
  display: flex !important;
  width: auto !important;
  flex-basis: 0% !important;
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
  align-items: center !important;
  justify-content: center !important;
  outline: none !important;
  padding: 0.135rem !important;
}

:global(.react-datepicker__day-names) {
  padding: 0.5rem 0 !important;
}

:global(.react-datepicker__day-names),
:global(.react-datepicker__week) {
  width: 100% !important;
  display: flex !important;
  justify-content: space-around !important;
}

:global(.react-datepicker__time-container) {
  float: none !important;
  border-color: var(--border_color_secondary) !important;
  min-height: 230px !important;
}

:global(.react-datepicker__time-list-item) {
  padding: 15px !important;
  height: auto !important;
}

:global(.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)) {
  right: 120px !important;
}

:global(.react-datepicker__time-list-item--selected),
:global(.react-datepicker__day--selected),
:global(.react-datepicker__day--in-selecting-range),
:global(.react-datepicker__day--in-range),
:global(.react-datepicker__month-text--selected),
:global(.react-datepicker__month-text--in-selecting-range),
:global(.react-datepicker__month-text--in-range),
:global(.react-datepicker__quarter-text--selected),
:global(.react-datepicker__quarter-text--in-selecting-range),
:global(.react-datepicker__quarter-text--in-range) {
  background-color: var(--color_brand) !important;
}

:global(.react-datepicker__current-month),
:global(.react-datepicker-time__header),
:global(.react-datepicker-year-header),
:global(.react-datepicker__day-name),
:global(.react-datepicker__day),
:global(.react-datepicker__time-name),
:global(.react-datepicker__time-list-item) {
  font-size: var(--fs_s) !important;
  color: var(--text_color_primary) !important;
}

:global(.react-datepicker__current-month),
:global(.react-datepicker-time__header),
:global(.react-datepicker-year-header) {
  font-weight: 500 !important;
  font-size: var(--fs_l) !important;
}

:global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover),
:global(.react-datepicker__day:hover),
:global(.react-datepicker__month-text:hover),
:global(.react-datepicker__quarter-text:hover) {
  background-color: var(--solid_color_primary-opaque) !important;
}

:global(.react-datepicker),
:global(.react-datepicker__time-container),
:global(.react-datepicker__time) {
  background-color: var(--solid_color_block) !important;
}

:global(.react-datepicker__time-container),
:global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box) {
  width: 100px !important;
}

@media (max-width: 500px) {
  :global(.react-datepicker) {
    flex-direction: column !important;
  }

  :global(.react-datepicker__month-container) {
    width: 100% !important;
  }

  :global(.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)) {
    right: 10px !important;
  }

  :global(.react-datepicker__header) {
    border-top: 1px solid var(--border_color_secondary) !important;
  }

  :global(.react-datepicker__time-container),
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box) {
    width: 100% !important;
  }

  :global(.react-datepicker__time-list),
  :global(.react-datepicker__time-container) {
    min-height: 150px !important;
  }
}
